<template>
  <vue-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="true"
    filename="hehehe"
    :paginate-elements-by-height="1100"
    :pdf-quality="1"
    pdf-format="a4"
    pdf-orientation="portrait"
    :manual-pagination="false"
    ref="html2Pdf"
    pdf-content-width="750px"
    :html-to-pdf-options="{ margin: [5, 5, 5, 5] }"
  >
    <section v-if="ready" slot="pdf-content">
      <h1>Đề {{ exam.code }}</h1>
      <div v-for="question in exam.questions" :key="question.id">
        <h4 class="mb-2">
          Câu {{ question.order }} - Level
          {{ question.originalQuestion.level }}
        </h4>
        <img :src="question.resizedContent" alt="" />
        <div
          style="margin-bottom:10px"
          v-for="choice in question.choices"
          :key="choice.id"
        >
          <p
            :style="{
              color: choice.originalChoice.isCorrect ? 'red' : 'unset',
              fontWeight: choice.originalChoice.isCorrect ? 'bold' : 'unset',
              fontSize: '14px',
              textDecoration: choice.originalChoice.isCorrect
                ? 'underline'
                : 'unset',
            }"
            class="my-2"
          >
            Phương án {{ choice.order }}:
            <span v-if="choice.originalChoice.type === 'text'">{{
              choice.originalChoice.content
            }}</span>
          </p>
          <img
            :src="choice.originalChoice.image"
            v-if="choice.originalChoice.image"
            alt=""
          />
        </div>
      </div>
    </section>
  </vue-html2pdf>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
function base64Resize(sourceBase64, scale, callBack) {
  var img = document.createElement("img");
  img.setAttribute("src", sourceBase64);
  return new Promise(function(resolve, reject) {
    img.onload = () => {
      var canvas = document.createElement("canvas");
      const _scale = img.width > 750 ? 750 / img.width : 1;
      canvas.width = img.width * _scale;
      canvas.height = img.height * _scale;

      var ctx = canvas.getContext("2d");
      var maxW = img.width * _scale;
      var maxH = img.height * _scale;

      var iw = img.width;
      var ih = img.height;
      var scl = Math.min(maxW / iw, maxH / ih);
      var iwScaled = iw * scl;
      var ihScaled = ih * scl;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
      const newBase64 = canvas.toDataURL("image/jpeg", 1);
      resolve(newBase64);
    };
  });
}
export default {
  components: { VueHtml2pdf },
  props: ["exam"],
  methods: {
    generatePdf() {
      if (!this.ready) {
        return this.$snackbar(
          "PDF chưa sẵn sàng, vui lòng chờ trong giây lát",
          "error"
        );
      }
      this.$refs.html2Pdf.generatePdf();
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  watch: {
    async exam(exam) {
      this.ready = false;
      for (const q of exam.questions) {
        const resizedContent = await base64Resize(q.originalQuestion.content);
        q.resizedContent = resizedContent;
      }
      this.ready = true;
    },
  },
};
</script>
