var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":false,"preview-modal":true,"filename":"hehehe","paginate-elements-by-height":1100,"pdf-quality":1,"pdf-format":"a4","pdf-orientation":"portrait","manual-pagination":false,"pdf-content-width":"750px","html-to-pdf-options":{ margin: [5, 5, 5, 5] }}},[(_vm.ready)?_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('h1',[_vm._v("Đề "+_vm._s(_vm.exam.code))]),_vm._l((_vm.exam.questions),function(question){return _c('div',{key:question.id},[_c('h4',{staticClass:"mb-2"},[_vm._v(" Câu "+_vm._s(question.order)+" - Level "+_vm._s(question.originalQuestion.level)+" ")]),_c('img',{attrs:{"src":question.resizedContent,"alt":""}}),_vm._l((question.choices),function(choice){return _c('div',{key:choice.id,staticStyle:{"margin-bottom":"10px"}},[_c('p',{staticClass:"my-2",style:({
            color: choice.originalChoice.isCorrect ? 'red' : 'unset',
            fontWeight: choice.originalChoice.isCorrect ? 'bold' : 'unset',
            fontSize: '14px',
            textDecoration: choice.originalChoice.isCorrect
              ? 'underline'
              : 'unset',
          })},[_vm._v(" Phương án "+_vm._s(choice.order)+": "),(choice.originalChoice.type === 'text')?_c('span',[_vm._v(_vm._s(choice.originalChoice.content))]):_vm._e()]),(choice.originalChoice.image)?_c('img',{attrs:{"src":choice.originalChoice.image,"alt":""}}):_vm._e()])})],2)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }